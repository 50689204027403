const size = {
    mobile: "470px",
    tablet: "834px",
    laptop: "1080px",
    desktop: "1440px",
}

export const devices = {
    mobile: `@media (max-width: ${size.mobile})`,
    tablet: `@media (max-width: ${size.tablet})`,
    laptop: `@media (max-width: ${size.laptop})`,
    desktop: `@media (max-width: ${size.desktop})`,
}
