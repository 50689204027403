export const formatTypes = {
    ddmmyyyy: 0,
    yyyymmdd: 1,
    mmddyyyy: 2,
}

/**
 * Format timestamp to DD-MM-YYYY.
 * @param {number} timestamp - Timestamp to be formatted.
 * @return {String} - A string formatted default date to DD-MM-YYYY.
 */

export default function(
    timestamp,
    seperator = "-",
    format = formatTypes.ddmmyyyy
) {
    if (!timestamp) return undefined
    const time = new Date(timestamp)
    const day = doublizeNumber(time.getDate())
    const month = doublizeNumber(time.getMonth() + 1)
    const year = time.getFullYear()
    const formattingTypes = {
        [formatTypes.yyyymmdd]: [year, month, day].join(seperator),
        [formatTypes.ddmmyyyy]: [day, month, year].join(seperator),
        [formatTypes.mmddyyyy]: [month, day, year].join(seperator),
    }
    return formattingTypes[format]
}

export function getDateWithHour(timestamp) {
    const date = new Date(timestamp)
    const day = doublizeNumber(date.getDate())
    const month = doublizeNumber(date.getMonth() + 1)
    const year = date.getFullYear()

    const second = doublizeNumber(date.getSeconds())
    const minute = doublizeNumber(date.getMinutes())
    const hour = doublizeNumber(date.getHours())

    return `${day}/${month}/${year} ${hour}:${minute}:${second}`
}

export const getHourinMS = hour => hour * 60 * 60 * 1000

//Formats number to double digit i.e. (1 => 01, 7 => 07, 11 => 11)
export const doublizeNumber = n => ("0" + n).slice(-2)

export const getMidNightInMS = timestamp =>
    new Date(timestamp).setHours(0, 0, 0, 0)

//Convert dd-mm-yyyy to yyyy-mm-dd
export const formatDateToBWT = date => {
    return date
        .split("-")
        .reverse()
        .join("-")
}
