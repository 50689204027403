import React, { useState, useEffect } from "react"
import { Box, Flex, Image, Text, Button, Span } from "src/styled"
import styled from "styled-components"
import { useSelector, useDispatch } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { closeProductModal } from "src/redux/modal"
import Counter from "./Card/Counter"
import { devices } from "src/theme/device"
import { addItem } from "src/redux/basket"
import { useLocation } from "@reach/router"

const Container = styled(Flex)`
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    padding: 10px;
    overflow: auto;
`
const StyledDescription = styled.p`
    font-family: Lato, sans-serif;
    font-weight: 400;
    font-size: 14px;
    padding: 16px;
    @media (max-width: 768px) {
        padding: 4px;
    }
`

const PriceContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
    @media (max-width: 768px) {
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 4px;
        text-align: center;
    }
`

const PricePrimary = styled.span`
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: black;
    margin-left: 4px;
`
const PriceSecondary = styled.span`
    font-family: Lato, sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #9098a2;
`
const Wrapper = styled(Flex)`
    background: white;
    border-radius: 10px;
    margin: auto;
    position: relative;
    width: 700px;
    height: fit-content;
    flex-direction: column;
    padding: 25px;

    ${devices.laptop} {
        width: 90%;
        padding: 10px;
    }
`

const Close = styled(Box)`
    border-radius: 50%;
    height: 30px;
    width: 30px;
    position: absolute;
    top: 10px;
    right: 10px;
    background: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: gray;
    border: 1px solid gray;
`

const Name = styled(Text)`
    font-size: 24px;
    font-weight: bold;
    text-align: center;
`

const Description = styled(Flex)`
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const Item = styled.p`
    padding: 3px;
    border-radius: 5px;
    font-size: 12px;
    margin: 0 5px 5px 0;
    color: gray;
    text-overflow: ellipsis;
    /* white-space: nowrap; */
    border: 1px solid ${({ theme }) => theme.colors.primary};
`

const ProductImage = styled(Image)`
    ${devices.tablet} {
        width: 100%;
        height: auto;
        max-height: 200px;
    }
    height: 200px;
    width: 200px;
    border-radius: 5px;
    object-fit: cover;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    :hover {
        transform: scale(1.01);
    }
`

const CloseButton = ({ onClick }) => (
    <Close onClick={onClick}>
        <FontAwesomeIcon icon={faTimes} />
    </Close>
)

const Background = styled(Box)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
`

const ImageGallery = styled(Flex)`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000000d1;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    z-index: 5;

    img {
        height: auto;
        width: 90%;
        margin: auto;
        max-height: 80%;
        object-fit: scale-down;
    }
`

const GalleryCloseButton = styled(Span)`
    top: 10px;
    right: 10px;
    position: fixed;
    svg {
        height: 40px !important;
        width: 40px !important;
        color: white;
    }
    cursor: pointer;
`
const ProductModal = () => {
    const location = useLocation()
    const productData = useSelector(state => state.modal.productData)
    const { name, price, description, id, image, desc } = productData
    const dispatch = useDispatch()
    const [previewingImage, setPreviewingImage] = useState(false)
    const handleKeyDown = event => {
        console.log(event)
        if (event.keyCode === 27) {
            onCloseModal()
        }
    }
    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown)
        return () => {
            window.removeEventListener("keydown", handleKeyDown)
        }
    }, [])
    const [counter, setCounter] = useState(1)
    const onIncreament = () => setCounter(counter + 1)
    const onDeacrement = () => setCounter(counter > 1 ? counter - 1 : 1)

    function onCloseModal() {
        dispatch(closeProductModal())
    }

    function onBuy() {
        if (!counter) return
        const item = { name, price, id, quantity: counter }
        dispatch(addItem(item))
        setCounter(1)
    }

    return (
        <>
            <Container>
                <Background onClick={onCloseModal} />

                <Wrapper>
                    <CloseButton onClick={onCloseModal} />
                    <Flex flexDirection={{ _: "column", md: "row" }}>
                        <Flex flexDirection="column" alignItems="center">
                            <ProductImage
                                onClick={() => setPreviewingImage(true)}
                                src={image}
                            />
                            <Text color="secondary" as="small">
                                Kliknij, aby powiększyć.
                            </Text>
                        </Flex>

                        <Description>
                            <Name>{name}</Name>
                            {/* <Text> {price}zł </Text> */}
                            <PriceContainer>
                                <div>
                                    <PriceSecondary>Cena</PriceSecondary>
                                    <PricePrimary>{price} zł</PricePrimary>
                                </div>
                            </PriceContainer>
                            <StyledDescription>{desc}</StyledDescription>
                        </Description>
                    </Flex>

                    <Flex my={10} flexWrap={"wrap"}>
                        {description &&
                            description.map((element, index) => (
                                <Item key={index}>{element}</Item>
                            ))}
                    </Flex>

                    <Flex
                        flexDirection={{ _: "column", lg: "row" }}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        mt={10}
                    >
                        {location.pathname.includes("menurestauracja") ? (
                            <></>
                        ) : (
                            <>
                                <Counter
                                    count={counter}
                                    onIncreament={onIncreament}
                                    onDeacrement={onDeacrement}
                                />

                                <Button
                                    variant="primary-thin"
                                    width={300}
                                    onClick={onBuy}
                                >
                                    Dodaj do koszyka
                                </Button>
                            </>
                        )}
                    </Flex>
                </Wrapper>
            </Container>

            {previewingImage && (
                <ImageGallery>
                    <img src={image} />
                    <GalleryCloseButton
                        onClick={() => setPreviewingImage(false)}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </GalleryCloseButton>
                </ImageGallery>
            )}
        </>
    )
}

export default ProductModal
