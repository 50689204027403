import styled from "styled-components"
import { color, layout, space, size, typography } from "styled-system"

const Text = styled.p`
  ${size}
  ${space}
  ${color}
  ${layout}
  ${typography}
`

export default Text
