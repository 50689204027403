import api, { apiName } from "./constants"
import { API } from "aws-amplify"

export async function postUser(userData) {
    const { phoneNumber, address, email, name, nip } = userData
    try {
        let request = {
            body: {
                phoneNumber,
                address,
                email,
                name,
                nip: nip || "-",
            },
        }

        return await API.post(apiName, api.user, request)
    } catch (error) {
        throw error
    }
}

export async function fetchUser(email) {
    try {
        const request = {
            body: {
                email,
            },
        }
        return await API.post(apiName, api.userData, request)
    } catch (error) {
        throw error
    }
}

export async function putUser(userData) {
    try {
        const request = {
            body: {
                ...userData,
            },
        }

        return await API.put(apiName, api.userData, request)
    } catch (error) {
        throw error
    }
}

export async function getUserAllOrders(email) {
    try {
        const request = {
            body: {
                from: 0,
                till: 100,
                id: email,
            },
        }

        return await API.post(apiName, api.userOrders, request)
    } catch (error) {
        throw error
    }
}
