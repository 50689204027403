import { API } from "aws-amplify"
import api, { apiName } from "./constants"

export async function getPrices() {
    try {
        return await API.get(apiName, `${api.delivery}/`, {})
    } catch (error) {
        console.error(error)
    }
}
