import { __prod__ } from "src/constants"
export const apiName = "przytomni-api"

//production
const production = {
    sushiSet: "/shop/sushiSet",
    order: "/shop/order",
    product: "/shop/product",
    ingredient: "/shop/ingredient",
    user: "/shop/client",
    userData: "/shop/client/data",
    userOrders: "/shop/client/history",
    systemClose: "/shop/close",
    welcome: "/shop/welcomeText",
    carousel: "/shop/carousel",
    delivery: "/shop/deliveryPrice",
    instagram: "/shop/instagram",
    menuSet: "/shop/menu/sushiSet",
    menuIngredient: "/shop/menu/ingredient",
    menuProduct: "/shop/menu/product",
}

//dev
const dev = {
    sushiSet: "/dev-shop/sushiSet",
    order: "/dev-shop/order",
    product: "/dev-shop/product",
    ingredient: "/dev-shop/ingredient",
    user: "/dev-shop/client",
    userData: "/dev-shop/client/data",
    userOrders: "/dev-shop/client/history",
    // systemClose: '/shop-dev/close',
    welcome: "/dev-shop/welcomeText",
    carousel: "/dev-shop/carousel",
    delivery: "/dev-shop/deliveryPrice",
    instagram: "/dev-shop/instagram",
    menuSet: "/dev-shop/menu/sushiSet",
    menuIngredient: "/dev-shop/menu/ingredient",
    menuProduct: "/dev-shop/menu/product",
}

export default __prod__ ? production : dev
