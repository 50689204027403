//@ts-check
import React, { useState, useEffect } from "react"
import { navigate } from "@reach/router"
import styled from "styled-components"
import Link from "../Link"
import { Flex } from "src/styled"
import { useSelector } from "react-redux"

const StyledBurger = styled(Flex)`
    flex-direction: column;
    justify-content: space-around;
    width: 1.8rem;
    height: 1.8rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 999;
    margin: auto 0 auto 10px;
    &:focus {
        outline: none;
    }

    div {
        width: 30px;
        height: 4px;
        background: ${({ theme, open }) =>
            open ? theme.colors.primaryLight : theme.colors.primaryDark};
        border-radius: 20px;
        transition: all 0.3s linear;
        position: relative;
        transform-origin: 1px;

        :first-child {
            transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
        }

        :nth-child(2) {
            width: 1.3rem;
            opacity: ${({ open }) => (open ? "0" : "1")};
            transform: ${({ open }) =>
                open ? "translateX(20px)" : "translateX(0)"};
        }

        :nth-child(3) {
            transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
        }
    }
`

const Slider = styled(Flex)`
    position: fixed;
    left: ${({ open }) => (open ? 0 : "-100vw")};
    top: 0;
    bottom: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background: ${props => props.theme.colors.bgDark};
    transition: 0.5s ease-in-out;
    justify-content: center;
    flex-direction: column;
    ${Link} {
        color: white;
        text-align: center;
        margin: 15px auto;
    }
`

const Burger = () => {
    const [open, setOpen] = useState(false)
    const { isAuthenticated, userData } = useSelector(state => state.user)

    function onNavigate(href) {
        setOpen(false)
        navigate(href)
    }

    useEffect(() => {
        const body = document.getElementsByTagName("body")[0]
        body.style.position = open ? "fixed" : "initial"
    }, [open])

    return (
        <Flex zIndex={2} flex={1} display={["flex", "flex", "none"]}>
            <StyledBurger open={open} onClick={() => setOpen(!open)}>
                <div />
                <div />
                <div />
            </StyledBurger>

            <Slider open={open}>
                <Link as="span" onClick={() => onNavigate("/#about")}>
                    O nas
                </Link>

                <Link as="span" onClick={() => onNavigate("/menu")}>
                    Zamów online
                </Link>

                <Link as="span" onClick={() => onNavigate("/about")}>
                    Jak zamawiać
                </Link>

                <Link as="span" onClick={() => onNavigate("/#contact")}>
                    Kontakt
                </Link>

                {!isAuthenticated ? (
                    <Link as="span" onClick={() => onNavigate("/auth/sign-in")}>
                        Konto
                    </Link>
                ) : (
                    <Link as="span" onClick={() => onNavigate("/profile")}>
                        Cześć {userData.name}
                    </Link>
                )}

                <Link as="span" onClick={() => onNavigate("/basket")}>
                    Koszyk
                </Link>

                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyItems: "center",
                        background: "#fff",
                        color: "#000",
                    }}
                >
                    <Link
                        as="span"
                        onClick={() =>
                            onNavigate("https://sklep.pracowniasushi.pl/")
                        }
                    >
                        <span style={{ color: "#000" }}>Sklep - dodatki</span>
                    </Link>
                </div>
            </Slider>
        </Flex>
    )
}

export default Burger
