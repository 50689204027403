import api from "./constants"

export async function getSets() {
    try {
        const response = await fetch(
            `https://api-przytomni.blesu.eu${api.sushiSet}`
        )
        return await response.json()
    } catch (error) {
        throw error
    }
}

export async function getMenuSets() {
    try {
        const response = await fetch(
            `https://api-przytomni.blesu.eu${api.menuSet}`
        )
        return await response.json()
    } catch (error) {
        throw error
    }
}
