import { navigate } from "@reach/router"
import {
    signUp as signUpAPI,
    signIn as signInAPI,
    getCurrentAuthenticatedUser,
    signOut as signOutAPI,
} from "src/api/rest/auth"
import {
    postUser,
    fetchUser,
    getUserAllOrders,
    putUser,
} from "src/api/rest/user"

const SIGN_IN = "auth/SIGN_IN"
const SIGN_OUT = "auth/SIGN_OUT"
const SET_AUTH_LOADING = "auth/SET_AUTH_LOADING"
const SIGN_IN_ERROR = "auth/SIGN_IN_ERROR"
const SIGN_UP_ERROR = "auth/SIGN_UP_ERROR"
const IS_FETCHING_USER_ORDERS = "auth/IS_FETCHING_USER_ORDERS"
const SET_USER_ORDERS = "auth/SET_USER_ORDERS"
const SET_USER_DATA = "auth/SET_USER_DATA"
const IS_UPDATING_USER_DATA = "auth/IS_UPDATING_USER_DATA"
const IS_VERIFIED = "auth/IS_VERIFIED"

const initState = {
    userData: {
        phoneNumber: "",
        address: "",
        email: "",
        name: "",
        nip: "",
    },
    isAuthenticated: false,
    isAuthenticating: false,
    signInError: "",
    signUpError: "",
    isFetchingUserOrder: false,
    userOrders: [],
    isUpadingUserData: false,
    isVerified: false,
}

export default function reducer(state = initState, action) {
    const { type, payload } = action

    const cases = {
        [SIGN_OUT]: () => ({
            ...state,
            userData: {},
            isAuthenticated: false,
        }),

        [SIGN_IN]: () => ({
            ...state,
            userData: payload,
            isAuthenticated: true,
            signInError: "",
            signUpError: "",
        }),

        [IS_VERIFIED]: () => ({
            ...state,
            isVerified: payload,
        }),

        [SIGN_IN_ERROR]: () => ({
            ...state,
            signInError: payload,
        }),

        [SET_AUTH_LOADING]: () => ({
            ...state,
            isAuthenticating: payload,
        }),

        [SIGN_UP_ERROR]: () => ({
            ...state,
            signUpError: payload,
        }),

        [IS_FETCHING_USER_ORDERS]: () => ({
            ...state,
            isFetchingUserOrder: payload,
        }),

        [SET_USER_ORDERS]: () => ({
            ...state,
            userOrders: payload,
        }),

        [SET_USER_DATA]: () => ({
            ...state,
            userData: {
                ...state.userData,
                ...payload,
            },
        }),
        [IS_UPDATING_USER_DATA]: () => ({
            ...state,
            isUpadingUserData: payload,
        }),
    }

    return cases[type] ? cases[type]() : state
}

//Actions
export const isAuthenticating = boolean => ({
    type: SET_AUTH_LOADING,
    payload: boolean,
})

const signInError = errorMessage => ({
    type: SIGN_IN_ERROR,
    payload: errorMessage,
})

const signUpError = errorMessage => ({
    type: SIGN_UP_ERROR,
    payload: errorMessage,
})

const isFetchinUserOrder = data => ({
    type: IS_FETCHING_USER_ORDERS,
    payload: data,
})

const setUserOrders = orders => ({
    type: SET_USER_ORDERS,
    payload: orders,
})

export const setUserData = data => ({
    type: SET_USER_DATA,
    payload: data,
})

const isUpdatingUserData = payload => ({
    type: IS_UPDATING_USER_DATA,
    payload,
})

const setIsVerified = payload => ({
    type: IS_VERIFIED,
    payload,
})

export const signInAction = userData => ({ type: SIGN_IN, payload: userData })
export const signOutAction = () => ({ type: SIGN_OUT })

export function updateUserData() {
    return async (dispatch, getState) => {
        dispatch(isUpdatingUserData(true))

        try {
            const { userData } = getState().user
            await putUser(userData)
        } catch (error) {
            console.error(error)
        }

        dispatch(isUpdatingUserData(false))
    }
}

export function signIn(email, password) {
    return async dispatch => {
        dispatch(isAuthenticating(true))
        try {
            await signInAPI(email, password)
            dispatch(fetchUserData(email))
            navigate("/")
        } catch (error) {
            dispatch(signInError(error))
        }

        dispatch(isAuthenticating(false))
    }
}

export function signUp(data) {
    return async (dispatch, getState) => {
        dispatch(isAuthenticating(true))

        const { password, repeatPassword, ...userData } = data

        try {
            await signUpAPI(userData.email, password)
            await postUser(userData)
            dispatch(signInAction(userData))
            navigate("/verify")
        } catch (error) {
            dispatch(signUpError(error))
        }

        dispatch(isAuthenticating(false))
    }
}

export function autoLogin() {
    return async dispatch => {
        dispatch(isAuthenticating(true))
        try {
            const userData = await getCurrentAuthenticatedUser()
            if (userData) dispatch(fetchUserData(userData.attributes.email))
        } catch (error) {
            // No authenticated user found
        }
        dispatch(isAuthenticating(false))
    }
}

export function checkEmailVerification() {
    return async dispatch => {
        try {
            const userData = await getCurrentAuthenticatedUser()
            const isVerified = userData.attributes.email_verified
            dispatch(setIsVerified(isVerified))
        } catch (error) {
            console.error(error)
        }
    }
}

export function fetchUserData(email) {
    return async dispatch => {
        try {
            const user = await fetchUser(email)
            dispatch(checkEmailVerification(email))
            dispatch(signInAction(user))
        } catch (error) {
            console.error(error)
        }
    }
}

export function signOut() {
    return async dispatch => {
        try {
            await signOutAPI()
            dispatch(signOutAction())
            navigate("/")
        } catch (error) {
            console.error(error)
        }
    }
}

export function fetchUserOrders() {
    return async (dispatch, getState) => {
        dispatch(isFetchinUserOrder(true))
        const { userData } = getState().user
        try {
            const response = await getUserAllOrders(userData.email)
            const { data: orders } = response
            dispatch(setUserOrders(orders))
        } catch (error) {
            console.error(error)
        }

        dispatch(isFetchinUserOrder(false))
    }
}
