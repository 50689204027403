import React from "react"
import styled from "styled-components"
import { Box, Header, Flex } from "src/styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { devices } from "src/theme/device"

const Container = styled(Flex)`
    z-index: 99;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Wrapper = styled(Flex)`
    background: white;
    border-radius: 10px;
    margin: auto;
    position: relative;
    min-width: 50%;
    height: fit-content;
    flex-direction: column;
    padding: 20px 20px 40px 20px;
    z-index: 2;
    ${devices.tablet} {
        width: 95%;
        padding: 30px;
    }
`

const Close = styled(Box)`
    border-radius: 50%;
    height: 30px;
    width: 30px;
    background: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: gray;
`

const CloseWelcome = styled.button`
    border-radius: 5px;
    height: 50px;
    width: 260px;
    background: black;
    font-size: 18px;
    cursor: pointer;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
`

const CloseWelcomeButton = ({ onClick }) => (
    <CloseWelcome onClick={onClick}>OK</CloseWelcome>
)

const CloseButton = ({ onClick }) => (
    <Close onClick={onClick}>
        <FontAwesomeIcon icon={faTimes} />
    </Close>
)

const Background = styled(Box)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    overflow: hidden;
`

const TopBar = styled(Flex)`
    align-items: center;
    justify-content: space-between;
    // margin-bottom: 40px;
    h2 {
        font-weight: bold;
    }
`

const Modal = ({ title, children, onClose, setMargin }) => {
    return (
        <Container>
            <Wrapper>
                <TopBar>
                    {setMargin ? (
                        <Header as="h1" m="20px auto" color="dark">
                            {title}
                        </Header>
                    ) : (
                        <>
                            <Header as="h2">{title}</Header>
                            <CloseButton onClick={onClose} />
                        </>
                    )}
                </TopBar>
                {setMargin ? (
                    <Flex
                        width="100%"
                        textAlign="center"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        flex={1}
                    >
                        {children}
                        <CloseWelcomeButton onClick={onClose} />
                    </Flex>
                ) : (
                    <Flex flex={1}>{children}</Flex>
                )}
            </Wrapper>
            <Background onClick={onClose} />
        </Container>
    )
}

export default Modal
