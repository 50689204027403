import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"

import Burger from "./Burger"
import { Flex, Box } from "src/styled"
import BasketModal from "./BasketModal"

import sushiLogo from "src/assets/images/new/logo_02_duze_cropped.png"
import userLogo from "src/assets/images/account.png"
import basketLogo from "src/assets/images/basket.png"

import {
    Logo,
    StyledImage,
    NavWrapper,
    NavContainer,
    StyledLink,
    Badge,
    BasketButton,
} from "./styled"
import { navigate, useLocation } from "@reach/router"
import styled from "styled-components"
import { paddingRight } from "styled-system"
import Link from "./Link"

const UserButton = styled(StyledLink)`
    align-items: center;
    display: flex;
`
function Navbar() {
    const [screenType, setScreenType] = useState("")
    useEffect(() => {
        if (window.innerWidth < 1080) {
            setScreenType("mobile")
        } else {
            setScreenType("desktop")
        }
        setBasketDisplay("none")
    }, [])
    const { items, totalPrice } = useSelector(state => state.basket)
    const { isAuthenticated, userData } = useSelector(state => state.user)
    const location = useLocation()

    function scrollTo(elementID) {
        //If user is not on the main page
        if (location.pathname !== "/") {
            navigate(`/#${elementID}`)
        } else {
            const elementPosition =
                document.getElementById(elementID).offsetTop - 80
            window.scrollTo({ top: elementPosition, behavior: "smooth" })
        }
    }

    function countTotalItems(items) {
        return items.reduce((total, item) => (total += item.quantity), 0)
    }
    function setBasketDisplay(type) {
        if (type === "none") {
            document.getElementById("basket").style.display = "none"
        } else if (type === "block") {
            document.getElementById("basket").style.display = "block"
        }
    }
    const handleBasketOnClick = () => {
        if (screenType === "desktop") {
            navigate("/basket")
            setTimeout(() => setBasketDisplay("none"), 100)
        } else {
            if (document.getElementById("basket").style.display == "block") {
                setBasketDisplay("none")
            } else {
                setBasketDisplay("block")
            }
        }
    }
    return (
        <NavContainer
            id="navbar"
            shadowed
            onMouseLeave={() => {
                setBasketDisplay("none")
            }}
        >
            <WideOnly>
                {/* Logo wide screens */}
                <Flex
                    width={"100px"}
                    justifyContent={"center"}
                    display={"none"}
                >
                    <Logo
                        onClick={() => scrollTo("presentation")}
                        alt="sushi-logo"
                        src={sushiLogo}
                    />
                </Flex>
            </WideOnly>

            {/* Basket */}
            <Burger scrollTo={scrollTo} />
            <NavWrapper>
                <StyledLink as="span" onClick={() => scrollTo("about")}>
                    O nas
                </StyledLink>

                <StyledLink as="span" onClick={() => navigate("/menu")}>
                    Zamów online
                </StyledLink>

                <StyledLink as="span" onClick={() => scrollTo("contact")}>
                    Kontakt
                </StyledLink>

                <StyledLink as="span" onClick={() => navigate("/about")}>
                    Jak zamawiać
                </StyledLink>

                <div
                    style={{
                        padding: 0,
                        paddingLeft: "16px",
                        paddingRight: "16px",
                        borderRadius: "6px",
                        background: "#000",
                        height: "auto",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Link
                        as="span"
                        onClick={() =>
                            navigate("https://sklep.pracowniasushi.pl/")
                        }
                        dark
                    >
                        Sklep - dodatki
                    </Link>
                </div>
            </NavWrapper>
            {/* Logo mobile*/}
            <Flex
                flex={10}
                justifyContent={"center"}
                display={["flex", "flex", "none"]}
            >
                <Logo
                    onClick={() => scrollTo("presentation")}
                    alt="sushi-logo"
                    src={sushiLogo}
                />
            </Flex>
            {/* Basket */}
            <Flex justifyContent={"flex-end"} zIndex={-1}>
                <BasketButton>
                    <Box display={"inline-block"} position={"relative"}>
                        <StyledImage
                            src={basketLogo}
                            onClick={() => handleBasketOnClick()}
                            onMouseEnter={() =>
                                screenType === "desktop"
                                    ? setBasketDisplay("block")
                                    : null
                            }
                            on
                        />
                        {items.length > 0 && (
                            <Badge> {countTotalItems(items)} </Badge>
                        )}
                    </Box>

                    <BasketModal
                        items={items}
                        totalPrice={totalPrice}
                        setBasketDisplay={setBasketDisplay}
                    />
                </BasketButton>

                {isAuthenticated && userData.name ? (
                    <UserButton to={"/profile"}>
                        <StyledImage
                            style={{ marginRight: 5 }}
                            src={userLogo}
                        />
                        Cześć {userData.name}
                    </UserButton>
                ) : (
                    <UserButton to={"/auth/sign-in"}>
                        <StyledImage src={userLogo} />
                    </UserButton>
                )}
            </Flex>
        </NavContainer>
    )
}

export default Navbar

const WideOnly = ({ children }) => {
    const [isDesktop, setIsDesktop] = useState(false) // Set default to false

    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth > 835)
        }

        // Check if window is defined before accessing it
        if (typeof window !== "undefined") {
            handleResize() // Set initial state based on window size
            window.addEventListener("resize", handleResize)
        }

        return () => {
            if (typeof window !== "undefined") {
                window.removeEventListener("resize", handleResize)
            }
        }
    }, [])

    return (
        <div
            style={{
                display: isDesktop ? "flex" : "none",
            }}
        >
            {children}
        </div>
    )
}
