import React from "react"
import { Provider } from "react-redux"

import store from "./src/redux/store"
import { __prod__ } from "src/constants.js"
import client from "./src/apollo/client"
import { ApolloProvider } from "react-apollo"

import { Helmet } from "react-helmet"
export default ({ element, location }) => {
    return (
        <ApolloProvider client={client}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    {__prod__
                        ? "Pracownia Sushi Wola"
                        : "Pracownia Sushi Wola - dev"}
                </title>
                <meta
                    name="theme-color"
                    content="#000000"
                    media="(prefers-color-scheme: light)"
                />
                <meta
                    name="theme-color"
                    content="#000000"
                    media="(prefers-color-scheme: dark)"
                />
            </Helmet>
            <Provider store={store}>{element}</Provider>
        </ApolloProvider>
    )
}
