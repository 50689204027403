import theme from "src/theme"
import styled from "styled-components"
import {
    color,
    typography,
    layout,
    space,
    fontStyle,
    border,
    variant,
} from "styled-system"

const Button = styled.button`
  ${color}
  ${space}
  ${border}
  ${layout}
  ${fontStyle}
  ${typography}
  text-transform: uppercase;
  transition: 0.25s ease-in;
  border: ${({ border }) => (border ? border : "none")};

  :hover{
    cursor: pointer;
    box-shadow: 4px 4px 5px -4px rgba(0,0,0,0.4);
  }

  :focus {
    outline: none;
  }

  ${variant({
      variants: {
          primary: {
              py: 15,
              px: 10,
              my: 10,
              fontSize: 16,
              borderRadius: 5,
              bg: "primary",
              color: "white",
              "&:hover": {
                  background: theme.colors.primaryDark,
              },
          },
          "primary-wide": {
              py: 15,
              px: 50,
              my: 10,
              fontSize: 16,
              borderRadius: 5,
              bg: "primary",
              color: "white",
              "&:hover": {
                  background: theme.colors.primaryDark,
              },
          },
          "primary-thin": {
              py: 10,
              px: 50,
              my: 10,
              fontSize: 16,
              borderRadius: 5,
              bg: "primary",
              color: "white",
              "&:hover": {
                  background: theme.colors.primaryDark,
              },
          },
          "primary-reverse": {
              py: 15,
              px: 10,
              my: 10,
              fontSize: 16,
              borderRadius: 5,
              bg: "white",
              color: "primary",
              border: `1px solid ${theme.colors.primaryDark}`,
          },

          "primary-reverse-wide": {
              py: 15,
              px: 50,
              my: 10,
              fontSize: 16,
              borderRadius: 5,
              bg: "white",
              color: "primary",
              border: `1px solid ${theme.colors.primaryDark}`,
          },

          link: {
              py: 15,
              px: 10,
              my: 10,
              fontSize: 16,
              border: "none",
              background: "none",
              color: "#000000",
          },
      },
  })}
`
export default Button
