import { getPrices } from "src/api/rest/delivery"
const SET_PRICES = "prices/SET_PRICES"

const initState = {
    ZONE_A: 10,
    ZONE_B: 20,
}

export default function(state = initState, action) {
    const { type, payload } = action

    const cases = {
        [SET_PRICES]: () => {
            return {
                ZONE_A: payload.zoneA,
                ZONE_B: payload.zoneB,
            }
        },
    }
    return cases[type] ? cases[type]() : state
}

export const setPrices = items => ({ type: SET_PRICES, payload: items })

export const fetchPrices = () => {
    return async dispatch => {
        try {
            const items = await getPrices()
            dispatch(setPrices(items))
        } catch (err) {
            console.error(err)
        }
    }
}
