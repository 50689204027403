import { round } from "src/utils"
import { clearBasketLocal, saveBasketLocal } from "src/utils/localstorage"

const ADD_ITEM = "basket/ADD_ITEM"
const CLEAR_BASKET = "basket/CLEAR_BASKET"
const DELETE_ITEM = "basket/DELETE_ITEM"
const SET_ITEMS = "basket/SET_ITEMS"
const INCREMENT_QUANTITY = "basket/INCREMENT_QUANTITY"
const DECREMENT_QUANTITY = "basket/DECREMENT_QUANTITY"

const initState = {
    items: [],
    totalPrice: 0,
}

export const clearBasket = () => ({
    type: CLEAR_BASKET,
})

export default function(state = initState, action) {
    const { type, payload } = action

    const cases = {
        [CLEAR_BASKET]: () => {
            clearBasketLocal()

            return {
                ...state,
                items: [],
                totalPrice: 0,
            }
        },

        [SET_ITEMS]: () => {
            const totalPrice = getTotalPrice(payload)
            return {
                ...state,
                items: payload,
                totalPrice,
            }
        },
        [ADD_ITEM]: () => {
            const items = addItems(state.items, payload)
            const totalPrice = getTotalPrice(items)
            saveBasketLocal(items)
            return {
                ...state,
                items,
                totalPrice,
            }
        },

        [DELETE_ITEM]: () => {
            const items = removeItem(state.items, payload)
            const totalPrice = getTotalPrice(items)
            saveBasketLocal(items)
            return {
                ...state,
                items,
                totalPrice,
            }
        },
        [INCREMENT_QUANTITY]: () => {
            const items = incrementQty(state.items, payload)
            const totalPrice = getTotalPrice(items)
            saveBasketLocal(items)
            return {
                ...state,
                items,
                totalPrice,
            }
        },
        [DECREMENT_QUANTITY]: () => {
            const items = decrementQty(state.items, payload)
            const totalPrice = getTotalPrice(items)
            saveBasketLocal(items)
            return {
                ...state,
                items,
                totalPrice,
            }
        },
    }

    return cases[type] ? cases[type]() : state
}

//Actions
export const addItem = item => ({ type: ADD_ITEM, payload: item })
export const deleteItem = id => ({ type: DELETE_ITEM, payload: id })
export const setItems = items => ({ type: SET_ITEMS, payload: items })
export const incrementItemQty = id => ({
    type: INCREMENT_QUANTITY,
    payload: id,
})
export const decrementItemQty = id => ({
    type: DECREMENT_QUANTITY,
    payload: id,
})

// helper functions
function addItems(items, newItem) {
    const index = items.findIndex(item => item.id === newItem.id)
    if (index === -1) items.push(newItem)
    else {
        items[index].quantity += newItem.quantity
    }
    return items
}
function incrementQty(items, id) {
    return items.filter(set => {
        if (set.id === id) {
            set.quantity = set.quantity + 1
            return set
        }
        return set
    })
}
function decrementQty(items, id) {
    return items.filter(set => {
        if (set.id === id && set.quantity > 1) {
            set.quantity = set.quantity - 1
            return set
        }
        return set
    })
}
function removeItem(items, id) {
    return items.filter(set => set.id !== id)
}

function getTotalPrice(items) {
    return round(
        items.reduce(
            (totalPrice, item) =>
                (totalPrice += parseFloat(item.price * item.quantity)),
            0
        ),
        2
    )
}
