import styled, { keyframes } from "styled-components"

const rotate = keyframes`
  to {
    transform: rotate(360deg);
  }
`
const LoadingIcon = styled.span`
    animation: ${rotate} 0.5s infinite linear;
    width: 20px;
    height: 20px;
    border: 2px solid ${({ theme, color }) => color || theme.colors.primary};
    border-top: 2px;
    border-left: 0px;
    border-right: 0px;
    border-radius: 50%;
    display: table;
    margin: auto;
`
export default LoadingIcon
