import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Box } from "src/styled"

const Input = styled.input`
    border-radius: 5px;
    border: 2px solid ${({ theme }) => theme.colors.lightGray};
    padding: 10px;
    width: 100%;
    font-size: 14px;
    margin-top: 10px;

    :focus {
        border: 2px solid ${({ theme }) => theme.colors.primary};
    }
`

const Label = styled.label`
    font-size: 16px;
    color: ${({ theme }) => theme.colors.primaryDark};
`

const Error = styled.small`
    color: red;
`

export const TextArea = styled.textarea`
    border-radius: 5px;
    border: 2px solid ${({ theme }) => theme.colors.lightGray};
    padding: 10px;
    width: 100%;
    font-size: 14px;
    margin-top: 10px;
    resize: none;
`

export default ({
    label,
    value = "",
    onChange,
    error,
    type = "date",
    ...rest
}) => {
    const [dateValue, setDateValue] = useState(value)

    const handleChange = e => {
        setDateValue(e.target.value)
        onChange(e)
    }

    useEffect(() => {
        setDateValue(value)
    }, [value])

    return (
        <Box mt={10} width={1}>
            <Label> {label} </Label>
            <Input
                type={type}
                value={dateValue}
                onChange={handleChange}
                {...rest}
            />
            {error && <Error color={"danger"}> {error} </Error>}
        </Box>
    )
}
