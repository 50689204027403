import { getCarousel } from "src/api/rest/carousel"
import { saveCarouselLocal } from "src/utils/localstorage"
const SET_CAROUSEL = "CAROUSEL/SET_CAROUSEL"

const initState = {
    carousel: [],
}

export default function(state = initState, action) {
    const { type, payload } = action

    const cases = {
        [SET_CAROUSEL]: () => {
            return {
                ...state,
                carousel: payload,
            }
        },
    }
    return cases[type] ? cases[type]() : state
}

export const setCarousel = items => ({ type: SET_CAROUSEL, payload: items })

export const fetchCarousel = () => {
    return async dispatch => {
        try {
            const items = await getCarousel()
            dispatch(setCarousel(items))
            saveCarouselLocal(items)
        } catch (err) {
            console.error(err)
        }
    }
}
