import api from "./constants"

export async function getProducts() {
    try {
        const response = await fetch(
            `https://api-przytomni.blesu.eu${api.product}`
        )
        return await response.json()
    } catch (error) {
        throw error
    }
}

export async function getMenuProducts() {
    try {
        const response = await fetch(
            `https://api-przytomni.blesu.eu${api.menuProduct}`
        )
        return await response.json()
    } catch (error) {
        throw error
    }
}
