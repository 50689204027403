import React from "react"
import styled from "styled-components"
import { Link } from "@reach/router"

import {
    typography,
    layout,
    space,
    fontStyle,
    border,
    variant,
} from "styled-system"
import theme from "src/theme"

const LinkButton = styled(Link)`
  ${space}
  ${border}
  ${layout}
  ${typography}
  ${fontStyle}
  border: ${({ border }) => (border ? border : "none")};
  :hover{
    box-shadow: 4px 4px 5px -4px rgba(0,0,0,0.4);
    cursor: pointer;
  }
  :focus {
    outline: none;
  }
  transition: 0.25s ease-in;
  text-transform: uppercase;

  ${variant({
      variants: {
          primary: {
              py: 10,
              px: 10,
              m: "10px",
              fontSize: 16,
              borderRadius: "5px",
              border: "none",
              bg: "primary",
              color: "white",
              textAlign: "center",
              "&:hover": {
                  background: theme.colors.primaryDark,
              },
          },

          "primary-basket": {
              py: 10,
              px: 15,
              my: 10,
              fontSize: 16,
              borderRadius: 5,
              bg: "primary",
              color: "white",
              "&:hover": {
                  background: theme.colors.primaryDark,
              },
          },
          "primary-reverse-basket": {
              textAlign: "center",
              py: 10,
              px: 15,
              m: 10,
              fontSize: 16,
              borderRadius: 5,
              border: `1px solid #000000`,
              bg: "white",
              color: "primary",
          },
          "primary-reverse": {
              textAlign: "center",
              py: 10,
              px: 10,
              m: 10,
              fontSize: 16,
              borderRadius: 5,
              border: `1px solid #000000`,
              bg: "white",
              color: "primary",
          },
      },
  })}
`

export default ({ children, bg, to, ...rest }) => (
    <LinkButton to={to} {...rest}>
        {children}
    </LinkButton>
)
