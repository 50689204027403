import React from "react"
import { TransitionProvider, TransitionViews } from "gatsby-plugin-transitions"
import { isIOS, isDesktop } from "react-device-detect"
const Layout = ({ location, children }) => {
    return (
        <TransitionProvider
            location={location}
            mode="successive"
            enter={{
                opacity: 0,
                config: {
                    duration: 500,
                },
            }}
            usual={{
                opacity: 1,
            }}
            leave={{
                opacity: 0,
                config: {
                    duration: 500,
                },
            }}
        >
            <TransitionViews>{children}</TransitionViews>
        </TransitionProvider>
    )
}

export default Layout
