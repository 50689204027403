import React from "react"
import styled from "styled-components"
import { Button, Flex } from "src/styled"
import { devices } from "src/theme/device"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import theme from "src/theme"

const PaginateButton = styled(Button)`
    border-radius: 50%;
    width: 45px;
    height: 45px;
    margin: 10px;
    ${devices.tablet} {
        width: 35px;
        height: 35px;
        margin: 5px;
    }
    padding: 0;
    border: 1px solid ${theme.colors.primary};
`

export default ({
    totalItems,
    itemsPerPage,
    activePage,
    setActivePage,
    numberOfButtons = 5,
}) => {
    const getTotalPages = (totalItems, itemsPerPage) =>
        Math.ceil(totalItems / itemsPerPage)

    const getMiddleButtonIndex = totalButtons =>
        Math.floor((totalButtons - 1) / 2)

    const getStartPage = (maxButtons, currentPage, totalPages) => {
        const middle = getMiddleButtonIndex(maxButtons)
        return currentPage + middle < totalPages
            ? Math.max(currentPage - middle, 1)
            : Math.max(totalPages - maxButtons + 1, 1)
    }

    const getLastPage = (maxButtons, currentPage, totalPages) => {
        const middle = getMiddleButtonIndex(maxButtons)

        return Math.min(
            totalPages - middle < currentPage
                ? totalPages
                : Math.max(currentPage + middle, maxButtons),
            totalPages
        )
    }

    function generateButtons(maxNumberOfButtons) {
        const totalPages = getTotalPages(totalItems, itemsPerPage)

        if (totalPages < 2) return null
        const buttons = []

        const startPage = getStartPage(
            maxNumberOfButtons,
            activePage,
            totalPages
        )
        const endPage = getLastPage(maxNumberOfButtons, activePage, totalPages)
        for (let i = startPage; i <= endPage; i++) {
            buttons.push(
                <PaginateButton
                    variant={i === activePage ? "primary" : "primary-reverse"}
                    onClick={() => setActivePage(i)}
                >
                    {i}
                </PaginateButton>
            )
        }

        const middle = getMiddleButtonIndex(maxNumberOfButtons)

        // Adding the arrows
        if (1 + middle < activePage) {
            buttons[0] = (
                <PaginateButton
                    variant={"primary-reverse"}
                    onClick={() => setActivePage(activePage - 1)}
                >
                    <FontAwesomeIcon icon={faArrowLeft} />
                </PaginateButton>
            )
        }

        if (totalPages - middle > activePage) {
            buttons[buttons.length - 1] = (
                <PaginateButton
                    variant={"primary-reverse"}
                    onClick={() => setActivePage(activePage + 1)}
                >
                    <FontAwesomeIcon icon={faArrowRight} />
                </PaginateButton>
            )
        }
        return buttons
    }

    return <Flex>{generateButtons(numberOfButtons)}</Flex>
}
