const config = {
    MAX_ATTACHMENT_SIZE: 5000000,
    s3: {
        REGION: "eu-central-1",
        BUCKET: "dysza-przytomni",
    },
    apiGateway: {
        REGION: "eu-central-1",
        URL: "https://api-przytomni.blesu.eu",
    },
    cognito: {
        REGION: "eu-central-1",
        USER_POOL_ID: "eu-central-1_XHjNaNXxr",
        APP_CLIENT_ID: "7j7s2l58t24ahe8cpsupv3ka20",
        IDENTITY_POOL_ID: "eu-central-1:5c415f42-f7c4-4ac0-951e-957135d55260",
    },
}

export default {
    Auth: {
        mandatorySignIn: false,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    },
    Storage: {
        region: config.s3.REGION,
        bucket: config.s3.BUCKET,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
    },
    API: {
        endpoints: [
            {
                name: "przytomni-api",
                endpoint: config.apiGateway.URL,
                region: config.apiGateway.REGION,
            },
        ],
    },
}
