const colors = {
    primaryDark: "#414141",
    primaryLight: "#cccccc",
    primary: "#000000",
    bgDark: "#131212",
    secondary: "#99a1ab",
    lightGray: "#ececec",
    danger: "#d9534f",
    lightBlue: "#f3f6ff",
    background: "#f1f1f1cc",
}

const space = {
    space: [0, 2, 4, 8, 16, 32, 64, 128, 256, 512],
}

const fontSizes = [12, 14, 16, 20, 24, 32, 40, 48]

fontSizes.xxs = fontSizes[0]
fontSizes.xs = fontSizes[1]
fontSizes.sm = fontSizes[2]
fontSizes.md = fontSizes[3]
fontSizes.lg = fontSizes[4]
fontSizes.xl = fontSizes[5]
fontSizes.xxl = fontSizes[6]
fontSizes.xxxl = fontSizes[7]

const border = ["2.5px", "5px", "7.5px", "10px", "12.5px"]
const breakpoints = ["480px", "834px", "1080px", "1440px"]
breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
breakpoints.xl = breakpoints[3]

export default {
    space,
    colors,
    border,
    fontSizes,
    breakpoints,
}
