const SET_SYSTEM = "system/SYSTEM"

const initState = {
    isDisabled: false,
}

export default function(state = initState, action) {
    const { type, payload } = action

    const cases = {
        [SET_SYSTEM]: () => {
            return {
                isDisabled: payload,
            }
        },
    }
    return cases[type] ? cases[type]() : state
}

export const setSystem = items => ({ type: SET_SYSTEM, payload: items })
