import React from "react"
import styled from "styled-components"
import { Flex, Span, Button } from "src/styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons"

const Container = styled(Flex)`
    width: fit-content;
    span {
        padding: 0px 8px;
    }
    overflow: hidden;
`

const Action = styled(Span)`
    display: flex;
    justify-content: center;
    align-items: center;
`

const Counter = styled(Span)`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
`

const PlusButton = styled(Button)`
    background-color: transparent;
    margin-right: 3px;
    font-size: 12px;
    border: solid grey 1px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
`
const MinusButton = styled(Button)`
    background-color: transparent;
    margin-left: 3px;
    font-size: 12px;
    border: solid grey 1px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    border: ${props =>
        props.disabled ? "solid lightgrey 1px" : "solid grey 1px"};
    pointer-events: ${props => (props.disabled ? "none" : "auto")};
`

const IncreamentIcon = () => {
    return (
        <PlusButton>
            <FontAwesomeIcon
                icon={faPlus}
                style={{
                    width: "10px",
                    height: "10px",
                    textAlign: "center",
                    color: "black",
                }}
            />
        </PlusButton>
    )
}
const DecrementIcon = ({ quantity }) => {
    return (
        <MinusButton quantity={quantity} disabled={quantity === 1}>
            <FontAwesomeIcon
                icon={faMinus}
                style={{
                    width: "10px",
                    height: "10px",
                    textAlign: "center",
                    color: `${quantity === 1 ? "lightgrey" : "black"}`,
                }}
            />
        </MinusButton>
    )
}
export default function CounterComponent({
    count,
    onIncreament,
    onDeacrement,
}) {
    return (
        <Container>
            <Action onClick={onDeacrement}>
                {/* <FontAwesomeIcon icon={faMinus} size={'sm'} /> */}
                <DecrementIcon quantity={count} />
            </Action>
            <Counter> {count} </Counter>
            <Action onClick={onIncreament}>
                {/* <FontAwesomeIcon icon={faPlus} size={'sm'} /> */}
                <IncreamentIcon />
            </Action>
        </Container>
    )
}
