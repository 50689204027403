import React, { useEffect, useState } from "react"
import Navbar from "./Navbar"

import { Flex, Text } from "src/styled"
import styled, { ThemeProvider } from "styled-components"
import { useSelector, useDispatch } from "react-redux"

import { autoLogin } from "src/redux/user"
import "../global.css"
import theme from "src/theme"
import ProductModal from "src/modules/menu/ProductModal"
import { fetchMenu } from "src/redux/menu/actions"
import { fetchMenu as fetchMenuRestaurant } from "src/redux/menurestaurant/actions"
import { checkSystem, setDisableSystemModal } from "src/redux/modal"
import { faTimes } from "@fortawesome/free-solid-svg-icons"

import { Modal } from "./common"
import { getBasketLocal, getCarouselLocal } from "src/utils/localstorage"
import { setItems } from "src/redux/basket"
import { getWelcomeMessage } from "src/api/rest/modal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { fetchCarousel, setCarousel } from "src/redux/carousel"
import { setSystem } from "src/redux/system"

const Main = styled.main`
    overflow: auto;
    width: 100%;
    height: fit-content;
    padding-top: 70px;
`

const Layout = ({ children, location }) => {
    const dispatch = useDispatch()
    console.log(location)
    const { isProductModalActive, systemDisableModalActive } = useSelector(
        state => state.modal
    )

    const [welcomeMessage, setWelcomeMessage] = useState("")

    useEffect(() => {
        if (location.pathname.includes("menurestauracja")) {
            dispatch(fetchMenuRestaurant())
        } else {
            //Get previous saved items list
            fetchCurrentMessage()
            const savedBasketItems = getBasketLocal()
            const savedCarousel = getCarouselLocal()
            dispatch(setItems(savedBasketItems))
            dispatch(autoLogin())
            dispatch(fetchMenu())
            dispatch(checkSystem())
            dispatch(setCarousel(savedCarousel))
        }
    }, [])

    async function fetchCurrentMessage() {
        try {
            const message = await getWelcomeMessage()
            if (message) {
                setWelcomeMessage(message.text)
                dispatch(setDisableSystemModal(message.isVisible))
                dispatch(setSystem(message.isSystemClose))
            }
        } catch (error) {
            throw error
        }
    }

    function onCloseModal() {
        dispatch(setDisableSystemModal(false))
    }

    return (
        <ThemeProvider theme={theme}>
            <Flex flexDirection={"column"} flex={1} position={"relative"}>
                <Navbar />
                <Main>{children}</Main>
                {isProductModalActive && <ProductModal />}
            </Flex>

            {systemDisableModalActive && (
                <Modal title={"INFORMACJA"} setMargin onClose={onCloseModal}>
                    <Text alignItem="center" color="red">
                        {welcomeMessage}
                    </Text>
                </Modal>
            )}
        </ThemeProvider>
    )
}

export default Layout
