import {
    SET_INGREDIENTS,
    SET_SETS,
    SET_PRODUCTS,
    SET_IS_LOADING,
    SET_MENU_SEARCH,
    SET_MENU_CATEGORY,
    SET_MENU_PAGE,
    SET_SELECTED_CATEGORY,
    SET_UNIQUE_CATEGORIES,
} from "./constants"

import {
    searchCategory,
    searchItems,
    getCategory,
    filterIngredients,
    filterProducts,
} from "./actions"

const initState = {
    ingredients: [],
    filteredIngredients: [],
    products: [],
    filteredProducts: [],
    sets: [],

    isLoading: false,
    selectedCategory: "",
    menuSearch: "",
    allCategories: [],
    filteredAndSearchedMenu: [],
    currentPage: 1,
}

export default function reducer(state = initState, action) {
    const { type, payload } = action
    const cases = {
        [SET_MENU_PAGE]: () => ({ ...state, currentPage: payload }),

        [SET_INGREDIENTS]: () => ({
            ...state,
            ingredients: payload,
            filteredIngredients: filterIngredients(payload),
        }),

        [SET_PRODUCTS]: () => ({
            ...state,
            products: payload,
            filteredProducts: filterProducts(payload),
        }),

        [SET_SETS]: () => ({
            ...state,
            sets: payload,
        }),

        [SET_IS_LOADING]: () => ({
            ...state,
            isLoading: payload,
        }),

        [SET_MENU_SEARCH]: () => {
            const { filteredProducts, filteredIngredients, sets } = state
            const allItems = [
                ...sets,
                ...filteredProducts,
                ...filteredIngredients,
            ]

            return {
                ...state,
                menuSearch: payload,
                selectedCategory: "",
                currentPage: 1,
                filteredAndSearchedMenu: searchItems(allItems, payload),
            }
        },

        [SET_SELECTED_CATEGORY]: () => ({
            ...state,
            selectedCategory: payload,
        }),

        [SET_UNIQUE_CATEGORIES]: () => ({ ...state, allCategories: payload }),

        [SET_MENU_CATEGORY]: () => {
            const {
                filteredProducts,
                filteredIngredients,
                sets,
                selectedCategory,
            } = state

            const allItems = [
                ...sets,
                ...filteredProducts,
                ...filteredIngredients,
            ]
            const newCategory = getCategory(selectedCategory, payload)

            return {
                ...state,
                selectedCategory: newCategory,
                currentPage: 1,
                menuSearch: "",
                filteredAndSearchedMenu: searchCategory(allItems, newCategory),
            }
        },
    }

    return cases[type] ? cases[type]() : state
}
