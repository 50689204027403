export const itemTypes = {
    set: "set",
    product: "product",
    ingredient: "ingredient",
}

export const productTypes = {
    maki: "Maki",
    nigiri: "Nigiri",
    sashimi: "Sashimi",
    zestaw: "Zestaw",
    zupy: "Zupy",
    desery: "Desery",
    sushi: "Sushi",
    przystawki: "Przystawki",
    dodatki: "Addons",
}

export const ingredientTypes = {
    alcohol: "Alcohol",
    beverages: "Napoje",
    ingredient: "Ingredient",
}

export const userPanel = {
    profile: "profile",
    history: "history",
}

export const statusTypes = {
    newOnline: "new-online",
}

export const __prod__ = process.env.GATSBY_IS_PROD == "true"
