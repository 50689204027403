import { itemTypes } from "src/constants"

export default function getItemType(id) {
    const firstChar = id.toString()[0]

    const options = {
        1: itemTypes.ingredient,
        2: itemTypes.product,
        3: itemTypes.set,
    }

    return options[firstChar]
}
