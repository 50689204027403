//@ts-check
import React from "react"
import styled from "styled-components"
import { devices } from "src/theme/device"
import { Link } from "react-scroll"

const TabContainer = styled.ul`
    display: ${({ isMobile }) => (isMobile ? "none" : "flex")};
    justify-content: space-between;
    width: fit-content;
    margin: auto;

    padding: 0 20px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    list-style-type: none;
    margin-top: 8px;

    ${devices.tablet} {
        display: ${({ isMobile }) => (isMobile ? "flex" : "none")};
    }
`

const Item = styled.span`
    padding: 5px 30px;
    position: relative;
    font-size: 18px;
    cursor: pointer;
    background: transparent;
    color: ${({ theme }) => theme.colors.primaryDark};
    border: none;
    border-bottom: 2px solid
        ${({ active, theme }) =>
            active ? theme.colors.primary : "transparent"};
    :focus {
        outline: none;
    }
    text-transform: capitalize;
    ${devices.tablet} {
        font-size: 16px;
        padding: 5px 15px;
    }
    :hover,
    :focus {
        color: ${({ theme }) => theme.colors.secondary};
        > div {
            display: block;
        }
    }
`

const Container = styled.nav`
    width: 100%;
    height: 40px;
    top: 0;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    border-bottom: 1px solid ${({ theme }) => theme.colors.secondary};
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }

    white-space: nowrap;
    overflow: auto hidden;
`

export default function Navigation({ tabs, activeTab, setActiveTab }) {
    return (
        <Container>
            {/* Desktop navigation */}
            <TabContainer>
                {tabs &&
                    tabs.map((tab, index) => (
                        <i key={index}>
                            <Item
                                active={activeTab === tab}
                                onClick={() => setActiveTab(tab)}
                            >
                                {tab === "Addons"
                                    ? "Dodatki"
                                    : tab && tab === "Beverages"
                                    ? "Napoje"
                                    : tab === "Alcohol"
                                    ? "Alkohole"
                                    : tab}
                            </Item>
                        </i>
                    ))}
            </TabContainer>

            {/* Mobile navigation */}
            <TabContainer isMobile>
                {tabs &&
                    tabs.map((tab, index) => (
                        <Link key={index} spy smooth offset={50} to={tab}>
                            {tab}
                        </Link>
                    ))}
            </TabContainer>
        </Container>
    )
}
