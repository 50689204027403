import {
    SET_INGREDIENTS,
    SET_SETS,
    SET_PRODUCTS,
    SET_IS_LOADING,
    SET_MENU_SEARCH,
    SET_MENU_CATEGORY,
    SET_MENU_PAGE,
    SET_SELECTED_CATEGORY,
    SET_UNIQUE_CATEGORIES,
} from "./constants"

import { ingredientTypes } from "src/modules/Main/constant"
import { getSets } from "src/api/rest/set"
import { getProducts } from "src/api/rest/product"
import { getIngredients } from "src/api/rest/ingredient"

export const setIngredients = items => ({
    type: SET_INGREDIENTS,
    payload: items,
})

const setUniqueCategories = categories => ({
    type: SET_UNIQUE_CATEGORIES,
    payload: categories,
})

export function fetchMenu() {
    return async dispatch => {
        dispatch(setIsLoading(true))
        try {
            const sets = await getSets()
            const products = await getProducts()
            const ingredients = await getIngredients()

            const filteredProducts = filterProducts(products)
            const filteredIngredients = filterIngredients(ingredients)

            dispatch(setSets(sets))
            dispatch(setProducts(products))
            dispatch(setIngredients(ingredients))
            dispatch(setMenuSearch(""))

            const uniqueCategories = getUniqueCategories([
                ...sets,
                ...filteredProducts,
                ...filteredIngredients,
            ])

            dispatch(setUniqueCategories(uniqueCategories))
            // dispatch(setMenuCategory(uniqueCategories[0]))
        } catch (error) {
            console.error("Error while getting menu.", error)
        }

        dispatch(setIsLoading(false))
    }
}

export const setProducts = items => ({
    type: SET_PRODUCTS,
    payload: items,
})

export const setSets = items => ({
    type: SET_SETS,
    payload: items,
})

export const setIsLoading = isLoading => ({
    type: SET_IS_LOADING,
    payload: isLoading,
})

export const setMenuCategory = category => ({
    type: SET_MENU_CATEGORY,
    payload: category,
})

export const setSelectedCategory = category => ({
    type: SET_SELECTED_CATEGORY,
    payload: category,
})

export const setMenuSearch = search => ({
    type: SET_MENU_SEARCH,
    payload: search,
})

export const setMenuPage = page => ({
    type: SET_MENU_PAGE,
    payload: page,
})

//Helper functions
export function searchItems(items, search) {
    if (!search || !search.trim()) {
        return items
    }

    const lowerCasedSearch = search.toLowerCase()

    return items.filter(item =>
        item.name.toLowerCase().includes(lowerCasedSearch)
    )
}

export function filterProducts(products) {
    return products.filter(product => product.isVisible)
}

export function filterIngredients(ingredients) {
    return ingredients.filter(
        ingredient => ingredient.category !== ingredientTypes.ingredient
    )
}

export function searchCategory(items, category) {
    if (!category || !category.trim()) return items

    return items.filter(item => item.category === category)
}

export function getCategory(oldCategory, newCategory) {
    return oldCategory !== newCategory ? newCategory : ""
}

function getUniqueCategories(menuList) {
    let categories = []
    menuList.forEach(
        ({ category }) =>
            !categories.some(e => e === category) && categories.push(category)
    )
    return categories
}
