import styled from "styled-components"
import { color, layout, space, size, grid, position } from "styled-system"

const Grid = styled.div`
  display: grid;
  ${grid}
  ${color}
  ${layout}
  ${size}
  ${space}
  ${position}
`
export default Grid
