import fetch from "node-fetch"
import ApolloClient from "apollo-boost"
import { __prod__ } from "src/constants"

const production = new ApolloClient({
    uri:
        "https://6zyv223myfblpbkzrv2xqtbkk4.appsync-api.eu-central-1.amazonaws.com/graphql",
    headers: {
        "x-api-key": "da2-tlswfg7gfvejhp2h55frheh77q",
    },
    fetch,
})

const dev = new ApolloClient({
    uri:
        "https://373ni2jvzvas5fn6bg3rpfyuge.appsync-api.eu-central-1.amazonaws.com/graphql",
    headers: {
        "x-api-key": "da2-6jsbwwdhhzdnblonssd4usvp74",
    },
    fetch,
})

// export default test
// export default production
export default __prod__ ? production : dev
