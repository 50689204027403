import { getSystemStatus } from "src/api/rest/system"
import { getWelcomeMessage } from "src/api/rest/modal"
import formatDate, { formatTypes } from "src/utils/formatDate"

const OPEN_PRODUCT_MODAL = "OPEN_PRODUCT_MODAL"
const CLOSE_PRODUCT_MODAL = "CLOSE_PRODUCT_MODAL"
const SET_SYSTEM_MODAL = "SET_SYSTEM_MODAL"
const SET_SYSTEM_DISABLE = "SET_SYSTEM_DISABLE"

const initialProduct = {
    name: undefined,
    price: undefined,
    description: undefined,
    id: undefined,
}

const initState = {
    isProductModalActive: false,
    productData: initialProduct,
    systemDisableModalActive: false,
    systemDisabled: false,
}

export default function reducer(state = initState, action) {
    const { type, payload } = action

    const cases = {
        [OPEN_PRODUCT_MODAL]: () => ({
            ...state,
            isProductModalActive: true,
            productData: payload,
        }),

        [CLOSE_PRODUCT_MODAL]: () => ({
            ...state,
            isProductModalActive: false,
            productData: initialProduct,
        }),

        [SET_SYSTEM_MODAL]: () => ({
            ...state,
            systemDisableModalActive: payload,
        }),

        [SET_SYSTEM_DISABLE]: () => ({
            ...state,
            systemDisabled: payload,
        }),
    }

    return cases[type] ? cases[type]() : state
}

//Actions
export const openProductModal = item => ({
    type: OPEN_PRODUCT_MODAL,
    payload: item,
})

export const closeProductModal = () => ({
    type: CLOSE_PRODUCT_MODAL,
})

export const setDisableSystemModal = isDisabled => ({
    type: SET_SYSTEM_MODAL,
    payload: isDisabled,
})

export const disableSystem = isDisabled => ({
    type: SET_SYSTEM_DISABLE,
    payload: isDisabled,
})

export function checkSystem() {
    return async dispatch => {
        try {
            // const date = formatDate(Date.now(), '-', formatTypes.ddmmyyyy)
            // const { status } = await getSystemStatus(date)
            // const { welcomeMessage } = await getWelcomeMessage()
            // dispatch(setDisableSystemModal(welcomeMessage.isVisible))
            // dispatch(disableSystem(status))
        } catch (error) {
            console.error(error)
        }
    }
}
