import api from "./constants"

export async function getIngredients() {
    try {
        const response = await fetch(
            `https://api-przytomni.blesu.eu${api.ingredient}`
        )
        return await response.json()
    } catch (error) {
        throw error
    }
}

export async function getMenuIngredients() {
    try {
        const response = await fetch(
            `https://api-przytomni.blesu.eu${api.menuIngredient}`
        )
        return await response.json()
    } catch (error) {
        throw error
    }
}
