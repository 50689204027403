import { createStore, applyMiddleware, compose } from "redux"
import rootReducer from "./rootReducer"
import thunk from "redux-thunk"

const middleware = [thunk]
const initState = {}
const windowGlobal = typeof window !== "undefined" && window

const devtools =
    process.env.NODE_ENV === "development" && windowGlobal.devToolsExtension
        ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
          window.__REDUX_DEVTOOLS_EXTENSION__()
        : f => f

const store = createStore(
    rootReducer,
    initState,
    compose(applyMiddleware(...middleware), devtools)
)

export default store
