// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-auth-sign-in-js": () => import("./../../../src/pages/auth/sign-in.js" /* webpackChunkName: "component---src-pages-auth-sign-in-js" */),
  "component---src-pages-auth-sign-up-js": () => import("./../../../src/pages/auth/sign-up.js" /* webpackChunkName: "component---src-pages-auth-sign-up-js" */),
  "component---src-pages-basket-js": () => import("./../../../src/pages/basket.js" /* webpackChunkName: "component---src-pages-basket-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-menurestauracja-js": () => import("./../../../src/pages/menurestauracja.js" /* webpackChunkName: "component---src-pages-menurestauracja-js" */),
  "component---src-pages-payment-js": () => import("./../../../src/pages/payment.js" /* webpackChunkName: "component---src-pages-payment-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-summary-js": () => import("./../../../src/pages/summary.js" /* webpackChunkName: "component---src-pages-summary-js" */),
  "component---src-pages-verify-js": () => import("./../../../src/pages/verify.js" /* webpackChunkName: "component---src-pages-verify-js" */)
}

