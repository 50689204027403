export const menuTypes = {
    ingredient: "Ingredient",
    product: "Product",
    sushi: "Sushi",
}

export const ingredientTypes = {
    alcohol: "Alcohol",
    beverages: "Beverages",
    ingredient: "Ingredient",
}
