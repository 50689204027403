import styled from "styled-components"
import { typography, space, color } from "styled-system"

const Table = styled.table`
    ${color}
`

const Row = styled.tr`
    ${color}
`

const Header = styled.th`
  ${space}
  ${color}
  ${typography}
`

const Data = styled.td`
  ${space}
  ${color}
  ${typography}
`

Table.Row = Row
Table.Data = Data
Table.Header = Header
export default Table
