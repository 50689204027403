//@ts-checkgg
import { API, Auth, Hub } from "aws-amplify"
import api, { apiName } from "./constants"

export async function signUp(email, password) {
    try {
        return await Auth.signUp({
            username: email,
            password,
            attributes: { email },
        })
    } catch (error) {
        throw error.message
    }
}

export async function isEmailVerified(email) {
    try {
        const request = {
            body: {
                id: email,
            },
        }

        return await API.post(apiName, `${api.user}/isActive`, request)
    } catch (error) {
        throw error.message
    }
}

export async function signIn(username, password) {
    try {
        return await Auth.signIn(username, password)
    } catch (error) {
        throw error.message
    }
}

export async function getCurrentAuthenticatedUser() {
    try {
        return await Auth.currentAuthenticatedUser()
    } catch (error) {
        throw error
    }
}

export async function signOut() {
    try {
        await Auth.signOut({ global: true })
    } catch (error) {
        throw error.message
    }
}

export async function resetPassword(email, newPassword, verificationCode) {
    try {
        return await Auth.forgotPasswordSubmit(
            email,
            verificationCode,
            newPassword
        )
    } catch (error) {
        throw "Kod weryfikacyjny jest nieprawidłowy lub hasło nie spełnia wymagań: Hasło musi posiadać przynajmniej 8 znaków, przynajmniej jedną wielką literę oraz cyfrę! "
    }
}

export async function sendVerificationResetPassword(email) {
    try {
        return await Auth.forgotPassword(email)
    } catch (error) {
        throw error.message
    }
}
