import styled from "styled-components"
import { layout, space, size, position, border } from "styled-system"

const Image = styled.img`
  ${layout}
  ${size}
  ${border}
  ${space}
  ${position}
`

export default Image
