import React from "react"
import styled from "styled-components"
import { Text, Flex, Grid, LinkButton } from "src/styled"

import { devices } from "src/theme/device"

export const Modal = styled(Flex)`
    display: none;
    top: 100%;
    z-index: 3;
    right: 0px;
    padding: 20px;
    position: absolute;
    background: white;
    text-align: center;
    flex-direction: column;
    min-width: 200px;
`

export const BasketOrderItem = styled(Grid)`
    padding: 5px;
    grid-column-gap: 30px;
    grid-auto-flow: column;
    border-bottom: 1px solid #cccccc;
    width: 350px;
    ${devices.laptop} {
        width: 250px;
    }
`

export default ({ items, totalPrice, setBasketDisplay }) => (
    <Modal shadowed id="basket">
        {items.length === 0 ? (
            <Text> Nie masz produktów w koszyku. </Text>
        ) : (
            <>
                <Text color={"secondary"}> Moje zamówienie </Text>
                <Text fontSize={24} mb={20}>
                    {totalPrice} zł
                </Text>
                {items.map((item, index) => (
                    <BasketOrderItem key={index}>
                        <Text textAlign={"left"}> x{item.quantity} szt </Text>
                        <Text textAlign={"center"}> {item.name} </Text>
                        <Text textAlign={"right"}>
                            {" "}
                            {item.price * item.quantity} zł{" "}
                        </Text>
                    </BasketOrderItem>
                ))}
                <Flex>
                    <LinkButton width={1} variant={"primary"} to={"/basket"}>
                        ZAMAWIAM
                    </LinkButton>
                </Flex>
            </>
        )}
    </Modal>
)
