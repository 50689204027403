const BASKET_KEY = "PSUSHI_LIST"
const CAROUSEL_KEY = "CAROUSEL_LIST"

export const saveBasketLocal = basketList => {
    const stringified = JSON.stringify(basketList)
    window.localStorage.setItem(BASKET_KEY, stringified)
}

export const getBasketLocal = () => {
    const list = window.localStorage.getItem(BASKET_KEY)
    if (!list) return []

    return JSON.parse(list)
}

export const clearBasketLocal = () => {
    window.localStorage.removeItem(BASKET_KEY)
}

export const saveCarouselLocal = carouselList => {
    const stringified = JSON.stringify(carouselList)
    window.localStorage.setItem(CAROUSEL_KEY, stringified)
}
export const getCarouselLocal = () => {
    const carousel = window.localStorage.getItem(CAROUSEL_KEY)
    if (!carousel || carousel === "undefined") return []
    return JSON.parse(carousel)
}
