import styled from "styled-components"
import { color, layout, space, size, typography } from "styled-system"

const Header = styled.h1`
  ${size}
  ${space}
  ${color}
  ${layout}
  ${typography}
`

export default Header
