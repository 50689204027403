import styled from "styled-components"
import {
    color,
    layout,
    space,
    size,
    flexbox,
    border,
    position,
    typography,
} from "styled-system"

const Flex = styled.div`
  ${size}
  ${typography}
  ${color}
  ${space}
  ${layout}
  ${border}
  ${flexbox}
  ${position}
  display: flex;
  ${props =>
      props.shadowed &&
      "box-shadow: 0 2px 2px 0 rgba(0,0,0,0.08), 0 1px 5px 0 rgba(0,0,0,0.07);"}
`
export default Flex
