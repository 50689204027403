/**
 * isDomAvailable
 * @description Checks to see if the DOM is available by checking the existence of the window and document
 * @see https://github.com/facebook/fbjs/blob/master/packages/fbjs/src/core/ExecutionEnvironment.js#L12
 */

export default function isDomAvailable() {
    return (
        typeof window !== "undefined" &&
        !!window.document &&
        !!window.document.createElement
    )
}
