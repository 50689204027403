import React from "react"
import Layout from "./src/components/layout"
import { configureAWSAmplify } from "./src/aws"

const wrapPageElement = ({ element, props }) => {
    configureAWSAmplify()
    return <Layout {...props}>{element}</Layout>
}

export default wrapPageElement
