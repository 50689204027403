import basket from "./basket"
import menu from "./menu/reducer"
import user from "./user"
import modal from "./modal"
import carousel from "./carousel"
import prices from "./prices"
import system from "./system"
import menurestaurant from "./menurestaurant/reducer"
import { combineReducers } from "redux"

export default combineReducers({
    basket,
    menu,
    modal,
    user,
    carousel,
    prices,
    system,
    menurestaurant,
})
