import styled from "styled-components"
import {
    color,
    layout,
    space,
    size,
    typography,
    border,
    position,
} from "styled-system"

const Text = styled.span`
  ${size}
  ${space}
  ${color}
  ${layout}
  ${position}
  ${border}
  ${typography}
`

export default Text
