import styled from "styled-components"
import {
    color,
    layout,
    space,
    size,
    position,
    shadow,
    border,
    typography,
} from "styled-system"

const Box = styled.div`
  ${size}
  ${space}
  ${color}
  ${layout}
  ${shadow}
  ${border}
  ${position}
  ${typography}
  ${props =>
      props.shadowed &&
      "box-shadow: 1px 2px 2px 0 rgba(0,0,0,0.1), 0 1px 5px 0 rgba(0,0,0,0.1);"}
`
export default Box
